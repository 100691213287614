import React from 'react'

const Map = ({ data }) => {
  if(data === undefined){
    return null
  }
  const {lat, long} = data

  return(
    <iframe title="PLDT Hospital" width="100%" height="350" frameBorder="0" style={{border: 0}}  src={`https://www.google.com/maps/embed/v1/place?q=${lat},${long}&key=AIzaSyDdm2sUGHq8H0OEye6CUo6mWyMFqFUy3PY`}></iframe>
  )
}

export default Map
