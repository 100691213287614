import React from "react"
import PropTypes from "prop-types"
import { Link } from 'gatsby'
import { Phone, Mail, MapPin } from 'react-feather'
import { Container, Row, Col, Image } from "react-bootstrap"
import { MAIN_NAVIGATION_ITEM } from '../../../mockup_data'

const Footer = ({ data }) => {
  const navItems = MAIN_NAVIGATION_ITEM

  if (data === undefined) {
    return null;
  }

  return (
    <footer>
      <Container className="py-5" id="footer-section">
        <Row>
          <Col xs={12} sm={12} md={4} lg={3} className="sm-py-3 order-0">
            <h6 className="mb-0 text-dark">CONTACT US</h6>
            <div className="d-flex flex-row mt-4">
              <Phone size={27} className="text-danger" />
              <p className="text-info mb-0 d-flex align-items-center ml-3 font-normal">{data.phone || 'No Data Available'}</p>
            </div>
            <div className="d-flex flex-row mt-4">
              <Mail size={27} className="text-danger" />
              <p className="text-info mb-0 d-flex align-items-center ml-3 font-normal">{data.email || 'No Data Available'}</p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={4} lg={4} className="sm-py-3 order-1">
            <h6 className="mb-0 text-dark">ADDRESS</h6>
            <div className="d-flex flex-row mt-4">
              <MapPin size={27} className="text-danger" />
              <p className="text-info mb-0 ml-3 font-normal w-75 d-flex align-items-center">{data.address || 'No Data Available'}</p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={4} lg={2} className="sm-py-3 order-2">
            <h6 className="mb-0 text-dark">QUICK LINKS</h6>
            <div className="d-flex flex-column mt-4">
              {navItems.map((item, i) =>
                item.label === "About Us"
                  ?
                  <Link
                    key={i}
                    as={Link}
                    to="/about-us/company-profile"
                    className="text-info font-normal text-decoration-none mb-2"
                  >
                    About Us
                </Link>
                  :
                  <Link
                    key={i}
                    as={Link}
                    to={item.path}
                    className="text-info font-normal text-decoration-none mb-2"
                  >
                    {item.label}
                  </Link>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3} className="d-flex justify-content-center flex-column align-items-center order-4 order-sm-4 order-md-4 order-lg-3 md-py-3">
            <Link to="/">
              <Image
                src={data.logo || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/d-image.svg'}
                className="image-hg"
              />
            </Link>
            <div className="d-flex justify-content-center">
              <a href="https://www.philhealth.gov.ph" target="_blank" rel="noreferrer noopener">
                <Image src={require("../../../images/philhealth.png")} className="images-sm h-50px w-120px mt-1" />
              </a>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} className="order-3 order-sm-3 order-md-3 order-lg-4 md-py-3">
            <p>In partnership with</p>
            <a href="https://www.seeyoudoc.com" target="_blank" rel="noreferrer noopener">
              <img src={require("../../../images/syd-logo-w-tagline.svg")} alt="user" className="h-70px mt-n2 mb-1" />
            </a>
            <div className="d-flex flex-row">
              <a href="https://apps.apple.com/ph/app/seeyoudoc/id1475857329" target="_blank" rel="noreferrer noopener">
                <img src={require("../../../images/app-store.svg")} alt="user" className="h-38px mr-1" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.sydmobilepatient" target="_blank" rel="noreferrer noopener">
                <img src={require("../../../images/play-store.svg")} alt="user" className="h-38px" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Footer 
