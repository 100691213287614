export const MAIN_NAVIGATION_ITEM = [
	{
		label: 'Home',
		path: '/',
		active: 'home'
	},
	{
		label: 'About Us',
		path: '/about-us/company-profile',
		active: 'about-us'
	},
	{
		label: 'Our Doctors',
		path: '/our-doctors',
		active: 'our-doctors'
	},
	{
		label: 'Our Services',
		path: '/our-services',
		active: 'our-services'
	},
	{
		label: 'Our Facilities',
		path: '/our-facilities',
		active: 'our-facilities'
	},
	{
		label: 'Promos & Events',
		path: '/promos-and-events',
		active: 'promos'
	},
	{
		label: 'Careers',
		path: '/careers',
		active: 'careers'
	},
	{
		label: 'Contact Us',
    path: '/contact-us',
		active: 'contact-us'
	},
]


export const PROMOS = [
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/promos/free-consultation-with-our-dernatoloies.png`,
		title: `Free Consultation with our Dernatoloies`,
		description: `Join us as we celebrate Skin Week in Metro Antipolo Hospital on NOVEMBER 24, 2019 with a FREE CONSULTATION with our DERMATOLOGISTS!`,
		body: [
			"Join us as we celebrate Skin Week in Metro Antipolo Hospital on NOVEMBER 24, 2019 with a FREE CONSULTATION with our DERMATOLOGISTS!",
			"Got any problems with your skin, hair and nails? Drop by our hospital's 3rd Floor Conference Room on November 24, 2019, Sunday from 8AM to 12NN and our Dermatologists will be happy to serve you! This event is open to everyone (Adult and Pedia) and is absolutely free for the FIRST 150 patients.",
			"Registration is on the main day itself.",
			"See you there! 😊",
		],
		date: 'August 18, 2019',
		slug: `free-consultation-with-our-dernatoloies`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1142201522835346/?type=3&theater',
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/promos/free-bone-screening.png`,
		title: `Free Bone Screening`,
		description: `Metro Antipolo Hospital and Medical Center invites you to a ZUMBA, OSTEOPOROSIS LAY FORUM and FREE BONE SCREENING, on October 26, 2019 at the hospital's 3rd floor Conference Room.`,
		body: [
			"Metro Antipolo Hospital and Medical Center invites you to a ZUMBA, OSTEOPOROSIS LAY FORUM and FREE BONE SCREENING, on October 26, 2019 at the hospital's 3rd floor Conference Room.",
			"8AM: Zumba",
			"9AM: Lay Forum by Dr. Vivien Mercado - Ner",
			"9:30AM - 12:00NN: Bone Screening (Open to first 100 patients, aged 45 years old and above)",
			"For inquiries and registration, you may call our Rehabilitation Unit at 8-722-3208 loc 3011.",
			"See you there! 😊",
		],
		date: 'August 18, 2019',
		slug: `free-bone-screening`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1118044915251007/?type=3&theater',
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/promos/free-pop-smear.png`,
		title: `Free Pop Smear`,
		description: `Metro Antipolo Hospital and Medical Center and Philippine Obstetrical and Gynecological Society - Metro Rizal, in cooperation with UNILAB, Inc.(Biofemme) and Scheeling, Inc. invites you to a FREE PAP SMEAR and LAY FORUM ABOUT CERVICAL CANCER AWARENESS AND ITS PREVENTION on October 6, 2019 at MAHMCI's 3rd floor Conference Room.`,
		body: [
			"Metro Antipolo Hospital and Medical Center and Philippine Obstetrical and Gynecological Society - Metro Rizal, in cooperation with UNILAB, Inc.(Biofemme) and Scheeling, Inc. invites you to a FREE PAP SMEAR and LAY FORUM ABOUT CERVICAL CANCER AWARENESS AND ITS PREVENTION on October 6, 2019 at MAHMCI's 3rd floor Conference Room.",
			"8AM - 12NN: Pap Smear (for the first 100 patients only)",
			"9AM: Lay Forum by Dr. Marizel Ann Sucayan - Sta.Ana, DPOGS",
			"To pre-register, you may call our Marketing Department at 722-3208 loc 6002",
			"See you there! 😊"
		],
		date: 'August 18, 2019',
		slug: `free-pop-smear`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1103656183356547/?type=3&theater',
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/promos/free-eye-check-up.jpg`,
		title: `Free eye check up`,
		description: `Save the date! August 18, 2019 will be our Free Eye Check up open to everyone interested!`,
		body: [
			"Save the date! August 18, 2019 will be our Free Eye Check up open to everyone interested! 👀",
			"8AM: Start of Registration and Free Clinic",
			"9AM: Lecture on Glaucoma by Dr. Paulo Jose Ponciano",
			"12NN: End of Free Clinic",
			"Avail of FREE CONSULTATION with our Ophthalmologists. We will also be giving free reading glasses (until supplies last)! 👓 See you!",
		],
		date: 'August 18, 2019',
		slug: `free-eye-check-up`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1058673944521438/?type=3&theater',
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/promos/family-doctors-caring-for-you-throughout-your-whole-life.png`,
		title: `Family Doctors Caring for you throughout your whole life`,
		description: `Save the date! May 26, 2019 will be our Free Check up open to all ages of your family members!`,
		body: [
			"Save the date! May 26, 2019 will be our Free Check up open to all ages of your family members!",
			"8AM: Start of Registration and Free Clinic",
			`9AM: Lecture on "Kalusugan para sa Pamilya" by Dr. Ana Marie Nicolas (President, PAFP, PAMAMARISAN Chapter)`,
			`12NN: End of Free Clinic`,
			`Venue: 3rd Floor Conference Room of the hospital`,
			`*Open to first 100 patients only`,
			`Avail of our following Free Services:`,
			`1. Nutritional Screening and Counseling`,
			`2. Ear piercing (Ear rings not included)`,
			`3. Anemia Screening and Sugar testing`,
			`4. Body Composition Analysis`,
			`See you there! 😊`,
		],
		date: 'August 18, 2019',
		slug: `family-doctors-caring-for-you-throughout-your-whole-life`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1001702470218586/?type=3&theater`
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/promos/20-off-anniversary-promo.jpg`,
		title: `20% OFF Anniversary Promo`,
		description: `As we celebrate our third anniversary on May 1, get 20% off on the following out patient services:`,
		body: [
			`As we celebrate our third anniversary on May 1, get 20% off on the following out patient services:`,
			`🎉 HEART STATION (All services: ECG, 2D Echo, Holter monitoring, and more)`,
			`🎉RADIOLOGY (X-ray, Ultrasound, CT Scan)`,
			`🎉OB ULTRASOUND (Transvaginal and Transrectal ultrasound and more)`,
			`🎉LABORATORY (CBC, BUN, FBS, Creatinine, Fecalysis, and Urinalysis only)`,
			`Runs from MAY 01-07, 2019 only!`,
			`Senior Citizens get +5% off on top of their mandatory discount!`,
			`NOT APPLICABLE to in patients, HMO card holders, stockholders, and dependents.`,
		],
		date: 'August 18, 2019',
		slug: `20-off-anniversary-promo`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.473844756337696/989311908124309/?type=3&theater`,
	},
]

export const EVENTS = [
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/events/seizures-in-pregnancy.png`,
		title: `Seizures in Pregnancy`,
		description: `Join us as we celebrate Skin Week in Metro Antipolo Hospital on NOVEMBER 24, 2019 with a FREE CONSULTATION with our DERMATOLOGISTS!`,
		body: [
			"Join us as we celebrate Skin Week in Metro Antipolo Hospital on NOVEMBER 24, 2019 with a FREE CONSULTATION with our DERMATOLOGISTS!",
			"Got any problems with your skin, hair and nails? Drop by our hospital's 3rd Floor Conference Room on November 24, 2019, Sunday from 8AM to 12NN and our Dermatologists will be happy to serve you! This event is open to everyone (Adult and Pedia) and is absolutely free for the FIRST 150 patients.",
			"Registration is on the main day itself.",
			"See you there! 😊",
		],
		date: 'August 18, 2019',
		slug: `free-consultation-with-our-dernatoloies`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1142201522835346/?type=3&theater',
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/events/nurses-week.jpg`,
		title: `Nurses' Week`,
		description: `Metro Antipolo Hospital and Medical Center invites you to a ZUMBA, OSTEOPOROSIS LAY FORUM and FREE BONE SCREENING, on October 26, 2019 at the hospital's 3rd floor Conference Room.`,
		body: [
			"Metro Antipolo Hospital and Medical Center invites you to a ZUMBA, OSTEOPOROSIS LAY FORUM and FREE BONE SCREENING, on October 26, 2019 at the hospital's 3rd floor Conference Room.",
			"8AM: Zumba",
			"9AM: Lay Forum by Dr. Vivien Mercado - Ner",
			"9:30AM - 12:00NN: Bone Screening (Open to first 100 patients, aged 45 years old and above)",
			"For inquiries and registration, you may call our Rehabilitation Unit at 8-722-3208 loc 3011.",
			"See you there! 😊",
		],
		date: 'August 18, 2019',
		slug: `free-bone-screening`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1118044915251007/?type=3&theater',
	},
	{
		img_url: `https://metro-antipolo-mh.s3-ap-southeast-1.amazonaws.com/events/fighting-the-rising-incidence-of-poliomyelitis-and-acute-flaccid-paralysis.png`,
		title: `Foighting the rising incidence of Poliomyelitis and Acute Flaccid Paralysis`,
		description: `Metro Antipolo Hospital and Medical Center and Philippine Obstetrical and Gynecological Society - Metro Rizal, in cooperation with UNILAB, Inc.(Biofemme) and Scheeling, Inc. invites you to a FREE PAP SMEAR and LAY FORUM ABOUT CERVICAL CANCER AWARENESS AND ITS PREVENTION on October 6, 2019 at MAHMCI's 3rd floor Conference Room.`,
		body: [
			"Metro Antipolo Hospital and Medical Center and Philippine Obstetrical and Gynecological Society - Metro Rizal, in cooperation with UNILAB, Inc.(Biofemme) and Scheeling, Inc. invites you to a FREE PAP SMEAR and LAY FORUM ABOUT CERVICAL CANCER AWARENESS AND ITS PREVENTION on October 6, 2019 at MAHMCI's 3rd floor Conference Room.",
			"8AM - 12NN: Pap Smear (for the first 100 patients only)",
			"9AM: Lay Forum by Dr. Marizel Ann Sucayan - Sta.Ana, DPOGS",
			"To pre-register, you may call our Marketing Department at 722-3208 loc 6002",
			"See you there! 😊"
		],
		date: 'August 18, 2019',
		slug: `free-pop-smear`,
		share_link: 'https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1103656183356547/?type=3&theater',
	}
]

export const SUITES = [
	{
		img_url: `https://www.kevinmd.com/blog/wp-content/uploads/shutterstock_48561808.jpg`,
		title: `Wards Room`,
		description: `In celebration of the Neurology Week 2019, Metro Antipolo Hospital and Medical Center is inviting everyone to a Lay Forum and Lecture about “SEIZURES IN PREGNANCY” on November 8, 2019, Friday, from 9:00AM to 11:00AM at the hospital's 3rd Floor Conference Room.`,
		body: [
			`In celebration of the Neurology Week 2019, Metro Antipolo Hospital and Medical Center is inviting everyone to a Lay Forum and Lecture about “SEIZURES IN PREGNANCY” on November 8, 2019, Friday, from 9:00AM to 11:00AM at the hospital's 3rd Floor Conference Room.`,
			`Speakers:`,
			`1. Dr. Ela A. Barcelon (Neurologist)`,
			`2. Dr. Genevieve Karen P. Chy (Perinatologist)`,
			`See you there!`,
		],
		date: `November 8, 2019`,
		slug: `seizures-in-pregnancy`,
		share_link: `https://currindesign.com/wp-content/uploads/2014/02/Centra-Hybrid-OR-5_800.jpg`,
	},
	{
		img_url: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFVpVL-FraWtWAfhzawHsUxVs-6X_uzRpVsz7Fck1LGs9UNSN6&s`,
		title: `Semi Private Room`,
		description: `Happy Nurses' Week to all the nurses! Especially to our dedicated and hardworking MAHMCI Nurses!`,
		body: [
			`Happy Nurses' Week to all the nurses! Especially to our dedicated and hardworking MAHMCI Nurses! 💪🦸♀️🦸 🦹♀️🦹♂️`,
			`#MAHMCINursesAreSuperheroes`,
		],
		date: `October 21 2019 - October 25, 2019`,
		slug: `nurses-week`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1122298878158944/?type=3&theater`,
	},
	{
		img_url: `https://media.bizj.us/view/img/11143646/scvmc-sobratopatient-room*750xx5760-3246-0-592.jpg`,
		title: `Private Room`,
		description: `Metro Antipolo Hospital and Medical Center is inviting everyone to an Advocacy Campaign - "Fighting the rising incidence of Poliomyelitis and Acute Flaccid Paralysis" on October 23, 2019, 1:30PM at the hospital's 3rd floor conference room. For inquiries, you may call our Marketing dept. at 8-722-3208 loc 6002.`,
		body: [
			`Metro Antipolo Hospital and Medical Center is inviting everyone to an Advocacy Campaign - "Fighting the rising incidence of Poliomyelitis and Acute Flaccid Paralysis" on October 23, 2019, 1:30PM at the hospital's 3rd floor conference room.`,
			`For inquiries, you may call our Marketing dept. at 8-722-3208 loc 6002.`,
		],
		date: `October 23 2019`,
		slug: `fighting-the-rising-incidence-of-poliomyelitis-and-acute-flaccid-paralysis`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1119086038480228/?type=3&theater`,
	}
]

export const CAREERS = [
	{
		img_url: `https://i0.wp.com/ofwjobhiring.com/wp-content/uploads/2018/01/NURSES-FOR-USA-job-hiring.png?fit=560%2C315&ssl=1`,
		title: `Hiring Nurses`,
		description: `In celebration of the Neurology Week 2019, Metro Antipolo Hospital and Medical Center is inviting everyone to a Lay Forum and Lecture about “SEIZURES IN PREGNANCY” on November 8, 2019, Friday, from 9:00AM to 11:00AM at the hospital's 3rd Floor Conference Room.`,
		body: [
			`In celebration of the Neurology Week 2019, Metro Antipolo Hospital and Medical Center is inviting everyone to a Lay Forum and Lecture about “SEIZURES IN PREGNANCY” on November 8, 2019, Friday, from 9:00AM to 11:00AM at the hospital's 3rd Floor Conference Room.`,
			`Speakers:`,
			`1. Dr. Ela A. Barcelon (Neurologist)`,
			`2. Dr. Genevieve Karen P. Chy (Perinatologist)`,
			`See you there!`,
		],
		date: `November 8, 2019`,
		slug: `seizures-in-pregnancy`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1135746343480864/?type=3&theater`,
	},
	{
		img_url: `https://i.ytimg.com/vi/YWCDohC7FO8/maxresdefault.jpg`,
		title: `Medical Representative`,
		description: `Happy Nurses' Week to all the nurses! Especially to our dedicated and hardworking MAHMCI Nurses!`,
		body: [
			`Happy Nurses' Week to all the nurses! Especially to our dedicated and hardworking MAHMCI Nurses! 💪🦸♀️🦸 🦹♀️🦹♂️`,
			`#MAHMCINursesAreSuperheroes`,
		],
		date: `October 21 2019 - October 25, 2019`,
		slug: `nurses-week`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1122298878158944/?type=3&theater`,
	},
	{
		img_url: `https://www.rasmussen.edu/-/media/images/blogs/school-of-nursing/different-types-of-nurses-1.png`,
		title: `We're hiring!`,
		description: `Metro Antipolo Hospital and Medical Center is inviting everyone to an Advocacy Campaign - "Fighting the rising incidence of Poliomyelitis and Acute Flaccid Paralysis" on October 23, 2019, 1:30PM at the hospital's 3rd floor conference room. For inquiries, you may call our Marketing dept. at 8-722-3208 loc 6002.`,
		body: [
			`Metro Antipolo Hospital and Medical Center is inviting everyone to an Advocacy Campaign - "Fighting the rising incidence of Poliomyelitis and Acute Flaccid Paralysis" on October 23, 2019, 1:30PM at the hospital's 3rd floor conference room.`,
			`For inquiries, you may call our Marketing dept. at 8-722-3208 loc 6002.`,
		],
		date: `October 23 2019`,
		slug: `fighting-the-rising-incidence-of-poliomyelitis-and-acute-flaccid-paralysis`,
		share_link: `https://www.facebook.com/officialmetroantipolo/photos/a.276940346028139/1119086038480228/?type=3&theater`,
	}
]

export const directories = [
	{ localNumber: "1000 / 0", departmentRoom: "Information / Lobby" },
	{ localNumber: "1001 / 1", departmentRoom: "Department of Anesthesiology" },
	{ localNumber: "1002 / 2", departmentRoom: "Department of Dental Medicine" },
	{ localNumber: "1003 / 3", departmentRoom: "Department of Emergency Medicine" },
	{ localNumber: "1004 / 4", departmentRoom: "Department of Family and Community Medicine" },
	{ localNumber: "1005 / 5", departmentRoom: "Department of Internal Medicine" },
	{ localNumber: "1006 / 6", departmentRoom: "Department of Obstetrics and Gynecology" },
	{ localNumber: "1007 / 7", departmentRoom: "Department of Ophthalmology" },
	{ localNumber: "1008 / 8", departmentRoom: "Department of Orthopedics" },
	{ localNumber: "1009 / 9", departmentRoom: "Department of Otorhinolaryngology" },
	{ localNumber: "1010 / 10", departmentRoom: "Department of Pediatrics" },
	{ localNumber: "1011 / 11", departmentRoom: "Department of Surgery" },
]

export const DEPARTMENTS = [
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Dermatology',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-dermatology'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Dental Medicine',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-dental-medicine'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Ear, Nose, Throat - Head and Neck Surgery',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-ear-nose-throat-head-and-neck-surgery'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Family Medicine',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-family-medicine'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Internal Medicine',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-internal-medicine'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Obstetrics-Gynecology',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-obstetrics-gynecology'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Ophthamology',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-ophthamology'
	},
	{
		img_url: require('../images/metro_antipolo.png'),
		name: 'Department of Pediatrics',
		description: 'The common recreation area and dining room for the commissioned officres on a warship',
		members: [
			"Dr. Abby Reyes",
			"Dr. Albert Victor",
			"Dr. Ali Tan",
			"Dr. Aliyah Bucad",
			"Dr. Arturo Wes",
			"Dr. Lilian Martinez",
			"Dr. Amy Adams",
			"Dr. Max Turner",
			"Dr. Julia Jameson",
			"Dr. Rebecca Kelly",
			"Dr. Melissa Adams",
			"Dr. Jack Peterson"
		],
		img_banner: 'https://www.canindia.com/wp-content/uploads/2018/04/DOCTORS.jpg',
		slug: 'department-of-pediatrics'
	}
]


export const DOCTOR = [
	{
		img_url: 'https://i.pravatar.cc/150?img=3',
		name: 'Abby Reyes',
		pratice: 'Dentist'
	}
]
