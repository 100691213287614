import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'
import { Card, Button } from 'react-bootstrap'
import './vertical_card.scss'

const VerticalCard = ({ img_url, title, description, slug }) => {
  return (
    <Card className="vertical-card w-100">
      <Card.Img
        variant="top"
        className="object-fit-cover image-h-md mb-0"
        src={img_url || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/banner.png'}
        alt={title}
      />
      <Card.Body>
        <Card.Title className="text-truncate h6 font-weight-bold">{title}</Card.Title>
        <Card.Text className="description text-muted">
          {description || 'No Data Available'}
        </Card.Text>
        <div className="d-flex justify-content-center w-100">
          <Button variant="link" as={Link} to={slug} className="text-dark-slate-blue">READ MORE</Button>
        </div>
      </Card.Body>
    </Card>
  )
}

VerticalCard.propTypes = {
  img_url: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
}

export default VerticalCard
