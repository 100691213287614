import React from 'react'
import PropTypes from 'prop-types'
import SubNavigation from './SubNavigation'
import MainNavigation from './MainNavigation'
import './navigation.scss'
import { MAIN_NAVIGATION_ITEM } from '../../../mockup_data'

const Navigation = ({ activeNav, data }) => {
  return(
    <div className="navigation">
      <SubNavigation data={data} /> 
      <MainNavigation data={data} activeNav={activeNav} navItems={MAIN_NAVIGATION_ITEM} />
    </div>
  )
}

Navigation.propTypes = {
  activeNav: PropTypes.string.isRequired
}

export default Navigation
