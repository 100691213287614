import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Carousel, ButtonToolbar, Button } from 'react-bootstrap'
import './carousel.scss'

const CarouselSlider = ({ data }) => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  if (data === undefined) {
    return null;
  }

  return (
    <Carousel className="sm-mt-5 sm-pt-3" activeIndex={index} direction={direction} onSelect={handleSelect}>
      {data.map((item, i) =>
        <Carousel.Item key={i}>
          <img
            className="d-block w-100 mb-0"
            src={item.imageUrl || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/banner.png'}
            alt={item.title}
          />
          <Carousel.Caption className="d-flex flex-wrap align-items-center justify-content-center h-100 mt-n5">
            <div className="d-flex flex-column justify-content-center text-center align-items-center">
            <h1>{item.title}</h1>
            <label>{item.description}</label>
            <ButtonToolbar className="mt-3">
              <Button
                as={Link}
                to="/our-doctors"
                variant="dark-slate-blue"
                size="lg"
                className="mr-3"
              >
                OUR DOCTORS
              </Button>
              <Button
                variant="primary"
                size="lg"
                as={Link}
                to="/our-services"
              >
                OUR SERVICES
              </Button>
              </ButtonToolbar>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      )}
    </Carousel>
  )
}


export default CarouselSlider
