import React from "react"
import PropTypes from "prop-types"
import { Navigation, Map, Footer } from '../index.js'

const Layout = ({ children, activeNav, data }) => {
  return (
    <>
    <div className="viewport">
      {/* All the main content gets inserted */}
      <Navigation activeNav={activeNav} data={data}/>
      <main className="site-main">
        {children}
      </main>

      {/* The footer at the very bottom of the screen */}
      <div className="viewport-bottom">
        <Map data={data}/>
        <Footer data={data}/>
      </div>
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activeNav: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default Layout
