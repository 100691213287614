import React, { Component } from "react"
import Slider from "react-slick"
import PropTypes from 'prop-types'
import { Image, Container } from 'react-bootstrap'
import "./hmo_slider.scss"

class HMOSlider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      height: 120,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    
    const { data } = this.props

    return (
      <div className="mt-5">
        <div className="text-center my-4">
          <h2 className="text-dark-slate-blue text-title">Our Accredited HMOs</h2>
        </div>
        <Container fluid className="hmo-slider border">
          <Slider {...settings}>
            {
              data.map((data, i)=>
                <div className="accredited-container d-flex justify-content-center align-items-center h-100" key={i}>
                  <Image src={data.avatar} alt={data.name} className="image-h-sm" />
                </div>
              )
            }
          </Slider>
        </Container>
      </div>
    );
  }
}

HMOSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired
}

export default HMOSlider
