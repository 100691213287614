import React, { useState } from 'react'
import PropTypes from "prop-types"
import { Phone, MapPin } from 'react-feather'
import { Link } from 'gatsby'
import { Container, Row, Col, Image, Modal, Button } from "react-bootstrap"

const SubNavigation = ({ data }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (data === undefined) {
    return null;
  }

  return (
    <div className="bg-white d-none d-sm-none d-md-none d-lg-block">

      {/* MODAL [START] */}
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-4">
          <h4 className="text-primary mb-0 text-title">Book Appointment Now!</h4>
          <p className="mt-2 mb-3">Select what you want to book.</p>
          <Row className="doctor-or-service-modal">
            <Col as={Link} to="/our-doctors" className="bg-dark-slate-blue ml-3 mr-1 d-flex flex-column align-items-center justify-content-center py-4">
              <Image src={require("../../../images/doctor.png")} className="card-image mb-4 image-md" />
              <h5 className="text-white mb-0 text-title">Doctor</h5>
            </Col>
            <Col as={Link} to="/our-services" className="bg-custom-secondary mr-3 ml-1 d-flex flex-column align-items-center justify-content-center py-4">
              <Image src={require("../../../images/service.png")} className="card-image mb-4 image-md" />
              <h5 className="text-white mb-0 text-title">Service</h5>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* MODAL [END] */}

      <Container>
        <Row className="my-4 sm-my-0 md-my-3 lg-my-5 ">
          <Col xs={6} sm={6} md={3} lg={3} className="order-1 order-sm-1 order-md-0 d-flex justify-content-center justify-content-sm-center justify-contentmd-start justify-content-lg-start px-0">
              <Link to="/">
                <Image
                  src={data.logo || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/d-image.svg'}
                  alt={data.name}
                  className="image-hg px-2 md-px-0"
                />
              </Link>
          </Col>

          <Col xs={3} sm={3} md={2} lg={3} className="order-2 order-sm-2 order-md-3 d-flex align-items-start align-items-sm-start align-items-md-start align-items-lg-center justify-content-end">
            <div className="d-flex flex-row flex-sm-row flex-md-row flex-lg-column">
              <Button
                onClick={handleShow}
                className="btn btn-danger btn-lg text-nowrap border-0"
              >
                Book Appointment
              </Button>
              <a href="https://account.seeyoudoc.com/login" rel="noreferrer noopener" className="btn btn-outline-danger mt-2">
                Login as Practitioner
              </a>
            </div>
          </Col>

          <Col xs={6} sm={8} md={3} lg={2} className="order-3 order-sm-4 order-md-1  d-flex justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-start">
            <div className="d-flex flex-row">
              <Phone size={25} className="text-danger" />
              <div className="d-flex flex-column ml-3">
                <p className="text-primary mb-0">CALL US</p>
                <p className="text-info mb-0">{data.phone || 'No Data Available'}</p>
              </div>
            </div>
          </Col>

          <Col xs={6} sm={8} md={4} lg={4} className="order-4 order-sm-7 order-md-2  d-flex justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-start pl-0">
            <div className="d-flex flex-row">
              <MapPin size={30} className="text-danger map-pin" />
              <div className="d-flex flex-column ml-3">
                <p className="text-primary m-0">ADDRESS</p>
                <p className="text-info f_10px">{data.address || 'No Data Available'}</p>
              </div>
            </div>
          </Col>

          <Col xs={3} sm={3} md={1} className="order-0 order-sm-0 order-md-4"></Col>
        </Row>
      </Container>
    </div>
  )
}

SubNavigation.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SubNavigation
