import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import { Nav, Navbar, Container, NavDropdown, Image, ButtonToolbar } from 'react-bootstrap'

const MainNavigation = ({ activeNav, navItems, data }) => {
  const [isMobileNav, setMobileNav] = useState(false);

  const handleOpenMobileNav = () => {
    setMobileNav(true)
  }

  const handleCloseMobileNav = () => {
    setMobileNav(false)
  }

  if (data === undefined) {
    return null;
  }
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="bg-dark-slate-blue p-0 stickynav border-bottom-md">
        <div className="w-100 d-flex flex-row justify-content-between align-items-center px-3 py-2 sm-py-0 d-lg-none">
          <Image
            src={data.logo || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/d-image.svg'}
            alt={data.name}
            className="image-sm p-2 md-px-0 d-block d-sm-block d-md-block d-lg-none"
          />
          <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
            <Menu size="28" onClick={handleOpenMobileNav} />
          </div>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Container>
            <Nav className="w-100">
              {navItems.map((item, i) =>
                item.label === "About Us"
                  ?<NavDropdown key={i} id="collasible-nav-dropdown" title="About Us" className={item.active === activeNav ? "active " : ""}>
                    <NavDropdown.Item as={Link} to="/about-us/company-profile">Company profile</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/about-us/mission-and-vision">Mission, Vision, and Core Values</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/about-us/medical-departments">Medical Departments</NavDropdown.Item>
                  </NavDropdown>
                  : 
                  <Nav.Link as={Link} to={item.path} className={item.active === activeNav ? 'active' : ' '} key={i}>
                    <span className="mb-0 nav-item text-white text-nowrap">{item.label}</span>
                  </Nav.Link>
              )}
              <a href="https://www.seeyoudoc.com/hospital/metro-antipolo-hospital-and-medical-center" className="d-lg-none nav-link">
                <span className="mb-0 nav-item text-white text-nowrap">Book Appointment</span>
              </a>
              <a href="https://account.seeyoudoc.com/login" className="d-lg-none nav-link">
                <span className="mb-0 nav-item text-white text-nowrap">Login as Practitioner</span>
              </a>
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Navbar>

      <div className={isMobileNav ? "mobile-menu" : "mobile-menu d-none"}>
        <div className="exit-icon-container">
          <X size={45} onClick={handleCloseMobileNav} />
        </div>

        <Fade bottom>
          <Nav variant="pills" defaultActiveKey="/home" className="flex-column text-left mt-2">
            {navItems.map((item, i) =>
              item.label === "About Us"
                ?
                <div key={i}>
                  <Navbar.Text>
                    About Us
              </Navbar.Text>
                  <Nav.Item className="ml-3">
                    <Nav.Link as={Link} to="/about-us/company-profile">Company profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ml-3">
                    <Nav.Link as={Link} to="/about-us/mission-and-vision">Mission, Vision</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ml-3">
                    <Nav.Link as={Link} to="/about-us/medical-departments">Medical Departments</Nav.Link>
                  </Nav.Item>
                </div>
                :
                <Nav.Item key={i}>
                  <Nav.Link as={Link} to={item.path} className={item.active === activeNav ? 'active' : ' '}>
                    {item.label}
                  </Nav.Link>
                </Nav.Item>
            )}
          </Nav>
        </Fade>

        <div className="sign-up-container">
          <ButtonToolbar className="d-flex flex-row justify-content-between">
            <Fade bottom cascade className="w-49">
              <div>
                <a
                  href="https://www.seeyoudoc.com/hospital/metro-antipolo-hospital-and-medical-center"
                  className="btn btn-dark-slate-blue btn-lg btn-block custom-btn-link"
                  rel="noreferrer noopener"
                >
                  <span> Book Appointment </span>
                </a>
              </div>
            </Fade>

            <Fade bottom cascade className="w-49">
              <div>
                <a
                  href="https://account.seeyoudoc.com/login"
                  className="btn btn-dark-slate-blue btn-lg btn-block custom-btn-link"
                  rel="noreferrer noopener"
                >
                  Login as Practitioner
              </a>
              </div>
            </Fade>
          </ButtonToolbar>
        </div>
      </div>
    </>
  )
}

MainNavigation.propTypes = {
  data: PropTypes.object.isRequired,
  activeNav: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      active: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired
}

export default MainNavigation
